<template>
  <div class="introduce">
    <img :src="imgSrc" class="introduce__img" />
  </div>
</template>
<script>
export default {
  name: 'introduce',
  data() {
    return {
      data: [
        {
          id: 'invoice',
          src: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/invoice/konw3.png'
        },
        {
          id: 'drop_shipping_order',
          src:
            'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/drop_shipping_order_images/index2.jpg'
        }
      ]
    };
  },
  computed: {
    imgSrc() {
      for (const i of this.data) {
        if (i.id === this.$route.params.id) {
          return i.src;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.introduce {
  &__img {
    width: 50%;
    display: block;
    margin: 20px auto;
  }
}
</style>